import makeFAIcon from "utils/makeFAIcon";

import { 
    faShoppingCart, 
    faLaptop, 
    faLock,
    faChartLine, 
    faWallet,
    faCreditCard, 
    faFileContract, 
    faCube, 
    faHandHoldingUsd, 
    faHouseUser, 
    faMicrochip, 
    faRobot, 
    faChessKnight } from "@fortawesome/free-solid-svg-icons";

export const ChartLineIcon = makeFAIcon(faChartLine);
export const WalletIcon = makeFAIcon(faWallet);
export const CreditCardIcon = makeFAIcon(faCreditCard);
export const BlockchainIcon = makeFAIcon(faCube);
export const FintechIcon = makeFAIcon(faHandHoldingUsd);
export const ContractIcon = makeFAIcon(faFileContract);
export const HouseIcon = makeFAIcon(faHouseUser);
export const ChipIcon = makeFAIcon(faMicrochip);
export const RobotIcon = makeFAIcon(faRobot);
export const KnightIcon = makeFAIcon(faChessKnight);

export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const LaptopIcon = makeFAIcon(faLaptop);
export const LockIcon = makeFAIcon(faLock);
